// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-content-page-js": () => import("./../src/layouts/ContentPage.js" /* webpackChunkName: "component---src-layouts-content-page-js" */),
  "component---src-layouts-splash-page-js": () => import("./../src/layouts/SplashPage.js" /* webpackChunkName: "component---src-layouts-splash-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-setup-index-js": () => import("./../src/pages/setup/index.js" /* webpackChunkName: "component---src-pages-setup-index-js" */)
}

